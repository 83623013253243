import {Survey} from '@/model/Survey'
import {defineStore} from 'pinia'
import {useHttp} from "~/composables/httpClient";
import {Section} from "~/model/Section";
import SectionForm from "~/forms/SectionForm";
import SurveyForm from "~/forms/SurveyForm";
import {Question} from "../model/Question";
import QuestionForm from "../forms/QuestionForm";
import CohortForm from "~/forms/CohortForm";
import SampleForm from "~/forms/SampleForm";
import {QuestionType} from "~/model/QuestionType";
import Form from "~/utils/form/Form";
import {Sample} from "~/model/Sample";
import {Cohort} from "~/model/Cohort";
import config from '../tailwind.config'
import {i18n} from "~/plugins/i18n";
import {useI18n} from "vue-i18n";
import {use} from "h3";

const colors = config.theme.extend.colors

export const useSurveyStore = defineStore("survey", () => {
    const survey: Ref<Survey | null> = ref(null);
    const surveyId: Ref<String | null> = ref('');
    const surveyForm: Ref<Form> = ref(SurveyForm({}))

    const section: Ref<Section | null> = ref(null);
    const sectionId: Ref<String | null> = ref('');
    const sectionForm: Ref<Form> = ref(SectionForm({}))

    const question: Ref<Question | null> = ref(null);
    const questionId: Ref<String | null> = ref('');
    const questionForm: Ref<Form> = ref(QuestionForm({}))

    const cohort: Ref<Cohort | null> = ref(null);
    const cohortId: Ref<String | null> = ref('');
    const cohortForm: Ref<Form> = ref(CohortForm({}))
    const cohorts: Ref<[]> = ref([])

    const sample: Ref<Sample | null> = ref(null);
    const samples: Ref<Sample | null> = ref([]);
    const samplesDetailed: Ref<Sample | null> = ref([]);

    const sampleId: Ref<String | null> = ref('');
    const sampleForm: Ref<Form> = ref(SampleForm({}))

    const sampling: Ref<any | null> = ref(null);
    const samplings: Ref<{}> = ref({});
    const samplingIds: Ref<Array<string>> = ref([]);

    const getSamples = async () => {
        let {data} = await useHttp().get(`/surveys/${useSurveyStore().survey.uuid}/samples/`)
        samples.value = data.value
    }


    const refreshSurvey = async () => {

        let url;
        if (useRouter().currentRoute.value.fullPath.indexOf('/admin') >= 0) {
            url = `/surveys/${surveyId.value}`;
        } else if (useRouter().currentRoute.value.fullPath.indexOf('/collaborators') >= 0) {
            url = `/surveys/current`;

        } else {
            url = `/respondent/survey`;
        }

        let {data, error} = await useHttp().get(url)

        if (data.value) {
            survey.value = data.value;
            surveyForm.value = SurveyForm(data.value);
            cohorts.value = data.value.cohorts
        }
    }

    watch(surveyId, async (newSurveyId) => {
        if (newSurveyId) {
            let url;
            if (useRouter().currentRoute.value.fullPath.indexOf('/admin') >= 0 || useRouter().currentRoute.value.fullPath.indexOf('/collaborators') >= 0) {
                url = `/surveys/${newSurveyId}`;
            } else {
                url = `/respondent/survey`;
            }
            let {data, error} = await useHttp().get(url)
            if (data.value) {

                survey.value = data.value;
                surveyForm.value = SurveyForm(data.value);
                let {data: _cohorts} = await useHttp().get(`/surveys/${survey.value.uuid}/cohorts`)
                cohorts.value = _cohorts.value
            }
        }
    })

    watch(sectionId, async (newSectionId) => {
        if (newSectionId) {
            let {data, error} = await useHttp().get(`/surveys/${surveyId.value}/sections/${newSectionId}`)

            if (data.value) {
                section.value = data.value;
                sectionForm.value = SectionForm(data.value);
            }
        }
    })

    watch(questionId, async (newQuestionId) => {
        if (newQuestionId) {
            let {
                data,
                error
            } = await useHttp().get(`/surveys/${surveyId.value}/sections/${sectionId.value}/questions/${newQuestionId}`)
            if (data.value) {
                question.value = data.value;
                questionForm.value = QuestionForm(data.value);
            }
        }
    })

    watch(cohortId, async (newCohortId) => {
        if (newCohortId) {
            let {data, error} = await useHttp().get(`/surveys/${surveyId.value}/cohorts/${newCohortId}`)
            if (data.value) {
                cohort.value = data.value;
                cohortForm.value = CohortForm(data.value);
            }
        }
    })

    watch(sampleId, async (newSampleId) => {
        if (newSampleId) {
            let {data, error} = await useHttp().get(`/surveys/${surveyId.value}/samples/${newSampleId}`)
            if (data.value) {
                sample.value = data.value;
                sampleForm.value = SampleForm(data.value);
            }
        }
    })

    watch(samplingIds, async (newSamplingIds) => {
        samplingIds.value.forEach(id => {
            if (Object.keys(samplings.value).includes(id)) {
                //If sampling already loaded, just enable
                samplings.value[id].enabled = true
            } else {
                //Load the sampling
                loadSampling(id)
                getSample(id)
            }
        })

        //disable sampling that are not listed
        for (let key in samplings.value) {
            if (!samplingIds.value.includes(key) && key !== 'all') {
                samplings.value[key].enabled = false
            }
        }

    })

    const cohortChoices = computed(() => {
        let cohortChoices = [];

        if (cohorts?.value) {
            cohortChoices = cohorts.value.map((c) => {
                return {label: c.title, value: c.uuid}
            });

        }
        return [...cohortChoices];
    })


    const loadSampling = async (samplingId: string) => {

        const url = samplingId === 'all' ? `/surveys/${surveyId.value}/sampling` : `/surveys/${surveyId.value}/samples/${samplingId}/sampling`

        if (url) {
            let {data, error} = await useHttp().get(url)
            if (data.value) {
                sampling.value = data.value;
                samplings.value[samplingId] = {enabled: true, data: [...data.value]};
            }
        }

    }

    const getSample = async (samplingId: string) => {

        const url = samplingId === 'all' ? `/surveys/${surveyId.value}/sampling` : `/surveys/${surveyId.value}/samples/${samplingId}`

        if (url) {
            let {data, error} = await useHttp().get(url)
            if (data.value) {
                samplesDetailed.value.push(data.value)
            }
        }

    }


    const getQuestion = (uuid: string) => {
        let question = null;
        if (survey.value) {
            survey.value.sections.forEach(s => {
                s.questions.forEach(q => {
                    if (q.uuid === uuid) {
                        question = q
                    }
                })
            })
        }
        return question;
    }

    const getSamplingsForQuestion = (question: Question) => {
        const example = {
            labels: getQuestionLabels(question),
            datasets: getSamplesDatasets(question)
        }
        return example;
    }

    const getSimpleSamplingsForQuestion = (question: Question) => {

        let _samples = [];

        const _samplings = JSON.parse(JSON.stringify(samplings.value));



        for (let key in _samplings) {
            const sampling = _samplings[key];

            const sample = samples.value.filter(s => s.uuid == key);

            let title = useI18n().t('samples.all');
            let color = colors.primary

            if (sample[0]) {
                title = sample[0].title;
                color = sample[0].color;
            }

            let sampleDetailed = samplesDetailed.value.find(s=>s.uuid == key)

            if(sampleDetailed)
            {
                if(sampleDetailed.respondent_count < 7)
                {
                    sampling.not_enough_respondent = true
                }
            }

            if (sampling.enabled && !sampling.not_enough_respondent) {
                let responses = sampling.data.filter(s => s.question_uuid === question.uuid)[0];

                if (responses) {

                    let no = Number(responses.response_sampling[0] ?? 0) + Number(responses.response_sampling[1] ?? 0)
                    let yes = Number(responses.response_sampling[2] ?? 0) + Number(responses.response_sampling[3] ?? 0)

                    let dataset = {
                        sampleLabel: title,
                        sampleColor: color,
                        data: question.inverted ? [no, yes] : [yes, no],
                        count:yes+no,
                        backgroundColor: [colors.success, colors.danger]
                    }

                    _samples.push(
                        {
                            labels: [
                                useI18n().t('label.yes'),
                                useI18n().t('label.no')
                            ],
                            datasets: [dataset]

                        }
                    )
                }

            }
        }
        return _samples;
    }


    const getQuestionLabels = (question: Question) => {
        const labels = [];

        switch (question.type) {
            case QuestionType.reducedScale:
                for (let key in [0, 1, 2, 3]) {

                    labels.push(useI18n().t('label.reduced_scale_' + key))
                }

                break;
            case QuestionType.extendedScale:

                for (let key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
                    labels.push(key)
                }
                break;
            case QuestionType.select:
                question.template.labels.forEach((l, i) => {
                    labels.push(l)
                })
                break;
        }

        return labels;
    };

    const getSamplesDatasets = (question: Question) => {
        let datasets = [];

        const _samplings = JSON.parse(JSON.stringify(samplings.value));

        for (let key in _samplings) {
            const sampling = _samplings[key];

            const sample = samplesDetailed.value.filter(s => s.uuid === key);

            let color = colors.primary
            let title = useI18n().t('samples.all');

            if (sample[0]) {
                color = sample[0].color;
                title = sample[0].title;
            }


            let sampleDetailed = samplesDetailed.value.find(s=>s.uuid == key)

            if(sampleDetailed)
            {
                if(sampleDetailed.respondent_count < 7)
                {
                    sampling.not_enough_respondent = true
                }
            }

            if (sampling.enabled && !sampling.not_enough_respondent) {
                let responses = sampling.data.filter(s => s.question_uuid === question.uuid)[0];

                let questionResponses = []

                let currentSample = sample.find(s=>s.uuid == key)

                let total = currentSample?.respondent_count ?? survey.value.respondent_count

                if (responses) {
                    switch (question.type) {
                        case QuestionType.reducedScale:
                            for (let key in [0, 1, 2, 3]) {

                                questionResponses.push(responses.response_sampling[key] ?? 0);
                            }
                            datasets.push({label: title, data: questionResponses, backgroundColor: color, count:responses.respondent_count, total:total})
                            break;
                        case QuestionType.extendedScale:
                            for (let key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
                                questionResponses.push(responses.response_sampling[key] ?? 0);
                            }
                            datasets.push({label: title, data: questionResponses, backgroundColor: color, count:responses.respondent_count, total:total})
                            break;
                        case QuestionType.select:

                            question.template.labels.forEach((l, i) => {
                                questionResponses.push(responses.response_sampling[i] ?? 0);
                            })
                            datasets.push({label: title, data: questionResponses, backgroundColor: color, count:responses.respondent_count, total:total})
                            break;
                    }
                }
            }
        }
        return datasets;
    };

    const getSamplingForQuestion = (question: Question) => {
        if (sampling.value) {

            let currentSampling = sampling.value.filter((s) => s.question_uuid === question.uuid)?.[0];

            if (currentSampling) {
                switch (question.type) {


                    case QuestionType.freeForm:
                        return JSON.parse(JSON.stringify(currentSampling)).response_sampling;


                    case QuestionType.reducedScale:
                        let response_reduced_scale_sampling = JSON.parse(JSON.stringify(currentSampling)).response_sampling;
                        let response_reduced_sampling_formatted = {};

                        for (let key in [0, 1, 2, 3]) {
                            response_reduced_sampling_formatted['reduced_scale_' + key] = response_reduced_scale_sampling[key] ?? 0;
                        }
                        return response_reduced_sampling_formatted;


                    case QuestionType.extendedScale:
                        let response_extended_scale_sampling = JSON.parse(JSON.stringify(currentSampling)).response_sampling;
                        let response_extended_scale_sampling_formatted = {};
                        for (let key in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
                            response_extended_scale_sampling_formatted[key] = response_extended_scale_sampling[key] ?? 0;
                        }
                        return response_extended_scale_sampling_formatted;

                    case QuestionType.select:
                        let response_select_sampling = JSON.parse(JSON.stringify(currentSampling)).response_sampling;
                        let response_select_sampling_formatted = {};
                        question.template.labels.forEach((l, i) => {
                            response_select_sampling_formatted[l] = response_select_sampling[i] ?? 0;
                        })
                        return response_select_sampling_formatted;

                }
            }
        }
    }


    return {
        survey,
        surveyId,
        surveyForm,

        section,
        sectionId,
        sectionForm,

        question,
        questionId,
        questionForm,

        cohort,
        cohortId,
        cohortForm,

        sample,
        sampleId,
        sampleForm,

        sampling,
        samplings,
        samplingIds,

        cohortChoices,
        refreshSurvey,
        loadSampling,
        getQuestion,
        getSamplingForQuestion,
        getSamplingsForQuestion,
        getSimpleSamplingsForQuestion,
        getSamples,
        getSample,
        samples,
        samplesDetailed
    };
})
